@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Sansita One';
    font-style: normal;
    font-weight: normal;
    src: local('Sansita One'), url('assets/fonts/SansitaOne.ttf') format('truetype');
}
*::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}
*::-webkit-scrollbar-track {
  border-radius: 0px;
  background-color: #DFE9EB;
}

*::-webkit-scrollbar-track:hover {
  background-color: #B8C0C2;
}

*::-webkit-scrollbar-track:active {
  background-color: #B8C0C2;
}

*::-webkit-scrollbar-thumb {
  border-radius: 0px;
  background-color: #397524;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #62A34B;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #62A34B;
}

.swiper-button-prev-custom,
.swiper-button-next-custom {
  width: 53px;
  height: 53px;
  position: absolute;
  cursor: pointer;
  z-index: 10;
  color: #767676; 
  transition: all 0.3s ease;
}

.swiper-button-prev-custom:hover,
.swiper-button-next-custom:hover {
  color: #767676;
}